import { authHooks } from "@app/auth";
import { twMerge } from "@app/common";
import { rbacUtils, Roles } from "@app/rbac";
import { laravelEcho, log } from "@app/services";
import { userHooks } from "@app/user";
import { Popover, Transition } from "@headlessui/react";
import {
    BuildingStorefrontIcon,
    CubeIcon,
    DocumentTextIcon,
    EnvelopeOpenIcon,
    HomeIcon,
    QueueListIcon,
    Square3Stack3DIcon,
    TruckIcon,
    WrenchIcon,
} from "@heroicons/react/24/outline";
import { UsersIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import { Avatar, Badge, Button, Text, Title } from "@ui";
import React, { Fragment, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { Link, useLocation } from "react-router-dom";
import "tippy.js/dist/tippy.css";

export interface ISidebarProps {
    isNewDeployment: boolean;
}

export function Sidebar({ isNewDeployment }: ISidebarProps) {
    const location = useLocation();
    const { user, activeDomain } = authHooks.useAuth();
    const { data: domains = [] } = userHooks.useGetUserDomains(user.id);
    const { mutateAsync } = authHooks.useUpdateSwitchDomain();
    const { mutateAsync: hookLogout } = authHooks.useLogout();
    const newOrderCount = domains.reduce((acc, domain) => acc + (domain.new_order_count || 0), 0);
    const activeDomainNewOrderCount = domains.find((domain) => domain.id === activeDomain.id)?.new_order_count || 0;
    const [referenceDomainElement, setReferenceDomainElement] = useState<any>();
    const [domainPopperElement, setDomainPopperElement] = useState<any>();
    const { styles: domainStyles, attributes: domainAttributes } = usePopper(referenceDomainElement, domainPopperElement, {
        placement: "right-start",
    });

    const [referenceUserAvatarElement, setReferenceUserAvatarElement] = useState<any>();
    const [userAvatarPopperElement, setUserAvatarPopperElement] = useState<any>();
    const { styles: userAvatarStyles, attributes: userAvatarAttributes } = usePopper(referenceUserAvatarElement, userAvatarPopperElement, {
        placement: "right-end",
    });

    const handleSwitch = (domainId: number) => {
        mutateAsync(domainId);
    };

    const handleLogout = () => {
        hookLogout();
    };

    useEffect(() => {
        domains.map((domain) => {
            const channel = laravelEcho
                .private(`domains.${domain.id}`)
                .listen(".NewOrderEvent", (data: any) => userHooks.useUpdateDomainOrderStatus(user.id, data[0].domain_id, data[0].count));

            log.debug("echo START listen", `domains.${domain.id}`, "event", "options", "queryKey");

            return () => {
                channel.stopListening(`domains.${domain.id}`);
            };
        });
    }, [domains, user]);

    const mainNavigationItems = [
        {
            handle: null,
            title: "Home",
            to: "/",
            heroIcon: HomeIcon,
            hint: null,
            default: true,
            roles: [Roles.User, Roles.SuperAdmin, Roles.Admin, Roles.Developer],
        },
        {
            handle: null,
            title: "Orders",
            to: "/orders",
            heroIcon: DocumentTextIcon,
            hint: null,
            default: true,
            roles: [Roles.User, Roles.SuperAdmin, Roles.Admin, Roles.Developer],
        },
        {
            handle: null,
            title: "Products",
            to: "/products",
            heroIcon: CubeIcon,
            hint: null,
            default: true,
            roles: [Roles.User, Roles.SuperAdmin, Roles.Admin, Roles.Developer],
        },
        {
            handle: null,
            title: "Returns",
            to: "/return-deliveries",
            heroIcon: WrenchIcon,
            hint: null,
            default: true,
            roles: [Roles.SuperAdmin, Roles.Admin, Roles.Developer, Roles.Warehouse],
        },
        {
            handle: null,
            title: "Channels",
            to: "/channels",
            heroIcon: Square3Stack3DIcon,
            hint: null,
            default: true,
            roles: [Roles.SuperAdmin, Roles.Admin, Roles.Developer],
        },
        {
            handle: null,
            title: "Warehouse",
            to: "/warehouses",
            heroIcon: BuildingStorefrontIcon,
            hint: null,
            default: true,
            roles: [Roles.SuperAdmin, Roles.Developer],
        },
        {
            handle: null,
            title: "Mail templates",
            to: "/mail-templates",
            heroIcon: EnvelopeOpenIcon,
            hint: null,
            default: true,
            roles: [Roles.SuperAdmin, Roles.Admin, Roles.Developer],
        },
        {
            handle: null,
            title: "User management",
            to: "/users",
            heroIcon: UsersIcon,
            hint: null,
            default: true,
            roles: [Roles.SuperAdmin, Roles.Developer],
        },
        {
            handle: null,
            title: "Queue",
            to: "/queues",
            heroIcon: QueueListIcon,
            hint: null,
            default: true,
            roles: [Roles.Developer],
        },
        {
            handle: null,
            title: "Carrier",
            to: "/carriers",
            heroIcon: TruckIcon,
            hint: null,
            default: true,
            roles: [Roles.SuperAdmin, Roles.Developer],
        },
    ];

    return (
        <React.Fragment>
            <div
                className={twMerge(
                    "md:flex pt-2.5 md:w-22 md:flex-col md:fixed md:inset-y-0 md:h-screen flex border-r border-slate-200 bg-white",
                    [isNewDeployment && "mt-10"]
                )}>
                <nav aria-label="Sidebar" className="hidden md:block md:flex-shrink-0 md:overflow-y-auto h-full">
                    <div className=" flex w-20 flex-col space-y-3 p-3 h-full">
                        <div className="flex-1">
                            {domains.length > 1 ? (
                                <Popover ref={setReferenceDomainElement}>
                                    <Popover.Button className="relative p-0.5 border border-slate-300 rounded-md ring-0 outline-none">
                                        {newOrderCount > 0 && (
                                            <div className="absolute -top-2.5 -right-2.5">
                                                <Badge className="shadow-lg" color="red">
                                                    {newOrderCount}
                                                </Badge>
                                            </div>
                                        )}
                                        <Avatar shape="square" color="yellow" size="md" url="" fallBackInitials={activeDomain.name} />
                                    </Popover.Button>

                                    <Popover.Panel
                                        className="p-1 shadow-xl ml-6 bg-white rounded-lg w-72"
                                        ref={setDomainPopperElement}
                                        style={domainStyles.popper}
                                        {...domainAttributes.popper}>
                                        {({ close }) => (
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1">
                                                <div className="block z-50 bg-white">
                                                    {domains.map((domain) => (
                                                        <div className="flex flex-row" key={domain.id}>
                                                            <div
                                                                className={twMerge(
                                                                    "flex items-center justify-start p-1 hover:bg-slate-100 m-0.5 rounded-md w-full",
                                                                    [domain.id === activeDomain.id && "bg-purple-50 cursor-default"],
                                                                    [domain.id !== activeDomain.id && "cursor-pointer"]
                                                                )}
                                                                onClick={() => {
                                                                    handleSwitch(domain.id);
                                                                    close();
                                                                }}>
                                                                <div className="p-0.5 bg-white rounded-md border-white">
                                                                    <Avatar shape="square" color="yellow" fallBackInitials={domain.name} />
                                                                </div>

                                                                <div className="px-2">
                                                                    <Title color="slate" truncate={true} size="sm">
                                                                        {domain.name}
                                                                    </Title>
                                                                    <Text size="xs">{domain.new_order_count || 0} open orders</Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Transition>
                                        )}
                                    </Popover.Panel>
                                </Popover>
                            ) : (
                                <div className="relative p-0.5 border border-slate-300 rounded-md flex items-center justify-center">
                                    {newOrderCount > 0 && (
                                        <div className="absolute -top-2.5 -right-2.5">
                                            <Badge className="shadow-lg" color="red">
                                                {newOrderCount}
                                            </Badge>
                                        </div>
                                    )}
                                    <Avatar shape="square" color="yellow" size="md" url="" fallBackInitials={activeDomain?.name} />
                                </div>
                            )}

                            {/* <Listbox value={activeDomain.id} onChange={handleSwitch}>
                                <div>
                                    <Listbox.Button className="group w-full flex items-center justify-center relative mt-3">
                                        <div className="relative p-0.5 border border-slate-300 rounded-md">
                                            <div className="absolute -top-2.5 -right-2.5">
                                                <Badge hasShadow={true} color="red">
                                                    1
                                                </Badge>
                                            </div>
                                            <Avatar shape="square" color="yellow" size="md" url="" fallBackInitials={activeDomain.name} />
                                        </div>
                                    </Listbox.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Listbox.Options className="z-10 origin-top absolute top-34 mt-1 right-0 left-0 rounded-md mx-2 shadow-xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                        {user.domains
                                            .filter((domain) => domain.id !== activeDomain.id)
                                            .map((domain) => (
                                                <Listbox.Option key={domain.id} value={domain.id}>
                                                    <Tippy content={domain.name} placement="right" key={domain.id}>
                                                        <div
                                                            className="flex items-center justify-center p-1"
                                                            onClick={() => handleSwitch(domain.id)}>
                                                            <Avatar shape="square" fallBackInitials={domain.name} />
                                                        </div>
                                                    </Tippy>
                                                </Listbox.Option>
                                            ))}
                                    </Listbox.Options>
                                </Transition>
                            </Listbox> */}
                            <div className="h-8 mb-8 border-b" />
                            {mainNavigationItems.map((item) => {
                                const url = getFirstValidPathSegment(location.pathname);
                                const isActive = `/${url}` === item.to || url === item.to;
                                const hasRequiredRole = rbacUtils.hasOneOfRoles(user.roles, item.roles);

                                return (
                                    hasRequiredRole && (
                                        <Tippy content={item.title} placement="right" key={item.title}>
                                            <Link
                                                title={item.title}
                                                to={item.to}
                                                className={twMerge(
                                                    isActive ? "bg-slate-400 text-white" : "text-slate-500 hover:bg-slate-200",
                                                    "flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-lg mx-1 relative my-1"
                                                )}>
                                                {item.title === "Orders" && activeDomainNewOrderCount > 0 && (
                                                    <div className="absolute -top-2 -right-2">
                                                        <Badge color="red">{activeDomainNewOrderCount}</Badge>
                                                    </div>
                                                )}
                                                <item.heroIcon className="h-5 w-5" aria-hidden="true" />
                                            </Link>
                                        </Tippy>
                                    )
                                );
                            })}
                        </div>

                        <div className="flex flex-shrink-0 pb-2.5">
                            <div className="w-full flex-shrink-0">
                                <Popover ref={setReferenceUserAvatarElement}>
                                    <Popover.Button className="ring-0 outline-none">
                                        <Avatar color="gray" url={user.avatar} fallBackInitials={`${user.full_name}`} />
                                    </Popover.Button>

                                    <Popover.Panel
                                        className="p-1 shadow-xl ml-6 bg-white rounded-lg w-28"
                                        ref={setUserAvatarPopperElement}
                                        style={userAvatarStyles.popper}
                                        {...userAvatarAttributes.popper}>
                                        {({ close }) => (
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1">
                                                <div className="block bg-white z-50 p-2">
                                                    <Button onClick={() => handleLogout()} className="my-2">
                                                        Logout
                                                    </Button>
                                                    <Text size="xs">Version 2.1</Text>
                                                </div>
                                            </Transition>
                                        )}
                                    </Popover.Panel>
                                </Popover>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </React.Fragment>
    );
}

/**
 * Private
 */

const getFirstValidPathSegment = (path: string) => {
    const segments = path && !path.includes("://") ? path.split("/") : [];

    return segments.find((segment) => !!segment && segment.trim() !== "/") || "/";
};
