import { useEffect, useRef, useState } from "react";

export const useForwardedRef = <T extends {}>(ref: React.ForwardedRef<T>): React.MutableRefObject<T | null> => {
    const innerRef = useRef(null);

    useEffect(() => {
        if (!ref) {
            return;
        }

        if (typeof ref === "function") {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef as React.MutableRefObject<T | null>;
};

export const useDebounce = (value: any, milliSeconds: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, milliSeconds);

        return () => {
            clearTimeout(handler);
        };
    }, [value, milliSeconds]);

    return debouncedValue;
};

export const useThrottle = <T>(value: T, interval = 500): T => {
    const [throttledValue, setThrottledValue] = useState<T>(value);
    const lastExecuted = useRef<number>(Date.now());

    useEffect(() => {
        if (Date.now() >= lastExecuted.current + interval) {
            lastExecuted.current = Date.now();
            setThrottledValue(value);
        } else {
            const timerId = setTimeout(() => {
                lastExecuted.current = Date.now();
                setThrottledValue(value);
            }, interval);

            return () => clearTimeout(timerId);
        }
    }, [value, interval]);

    return throttledValue;
};
