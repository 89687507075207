export const fontSizes = {
    xs: "text-xs",
    sm: "text-sm",
    base: "text-base",
    lg: "text-lg",
    xl: "text-xl",
    twoXl: "text-2xl",
} as const;


export const fontWeights= {
    light: "font-light",
    base: "font-normal",
    medium: "font-medium",
    semiBold: "font-semibold",
    bold: "font-bold",
} as const;

export const textAlignments = {
    left: "text-left",
    center: "text-center",
    right: "text-right",
    justify: "text-justify",
    start: "text-start",
    end: "text-end",
} as const;
