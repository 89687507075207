import { createContext } from "react";
import { INotification, TNotificationData, TNotificationId } from "./types";

export interface INotificationManagerContext {
    notifications: INotification[];
    pushNotification: (notification: TNotificationData) => TNotificationId;
    removeNotification: (id: string) => void;
}

export const NotificationManagerContext = createContext<INotificationManagerContext>({
    notifications: [],
    pushNotification: () => "",
    removeNotification: () => undefined,
});
