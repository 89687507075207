import { axios } from "@app/services";
import { EnvelopeIcon, EyeIcon, EyeSlashIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/componentLibrary";
import { config } from "../config";
import { useLogin } from "../hooks";
import { ILoginProps } from "../queries";

export const LoginPage = () => {
    const { register, handleSubmit } = useForm<ILoginProps>();
    const [error, setError] = React.useState<string | null>(null);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const login = useLogin();

    const onSubmit = (props: ILoginProps) => {
        axios.get(config.sanctumUrl).then(() => {
            login.mutate(props, {
                onSuccess: () => navigate("/"),
                onError: (loginError) => {
                    if (loginError.response?.status === 401) {
                        setError("Email and password combination is incorrect.");
                    }
                },
            });
        });
    };

    const togglePassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <div className="min-h-full flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        {/* <img src="/LogoSample_ByTailorBrands.jpg" width={100} alt="" /> */}
                        <h2 className="mt-6 text-2xl text-gray-900 font-heading">Sign in to your account</h2>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <div className="mt-1 relative rounded-md">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            {...register("email")}
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email username"
                                            placeholder="Your e-mail address"
                                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <div className="mt-1 relative rounded-md">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            {...register("password")}
                                            id="password"
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="current-password password"
                                            required
                                            placeholder="Password"
                                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                        <div className="absolute inset-y-0 right-0 pr-3 text-gray-500 sm:text-sm flex items-center">
                                            {showPassword ? (
                                                <EyeSlashIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                                            ) : (
                                                <EyeIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input
                                            {...register("remember")}
                                            id="remember"
                                            name="remember"
                                            type="checkbox"
                                            className="h-4 w-4 text-hc_green-700 focus:text-hc_green-900 border-gray-300 rounded"
                                        />
                                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                            Remember me next time
                                        </label>
                                    </div>

                                    {/* <div className="text-sm">
                                <a href="/auth/forgot-password" className="font-medium text-hc_green-700 hover:text-hc_green-900">
                                    Forgot my password?
                                </a>
                            </div> */}
                                </div>
                                {error && <div className="text-sm text-red-700">{error}</div>}
                                <Button variant="primary" color="purple" type="submit">
                                    Sign in
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""
                />
            </div>
        </div>
    );
};
